import React from "react";
import { Form } from "antd";

export default (props) => (
  <Form
    validateTrigger="onSubmit"
    layout="inline"
    css={{
      ".ant-form-item-with-help": { marginBottom: "0 !important" },
      ".ant-form-item-explain": {
        height: "0 !important",
        minHeight: "0 !important",
      },
    }}
    {...props}
  />
);
