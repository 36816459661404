import React from "react";
import { Space, Dropdown, Menu, Button } from "antd";
import {
  BulbOutlined,
  GiftOutlined,
  DollarOutlined,
  QuestionOutlined,
  MenuOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import { Link } from "gatsby";
import mq from "./mq";
import useTranslation from "../lang/useTranslation";
import { isAuthed } from "./api";
import { Title } from "./typography";

const MenuLink = ({ white, ...props }) => (
  <Title
    css={{
      marginBottom: "0 !important",
      fontSize: "0.8em !important",
      display: "inline",
    }}
    level={4}
  >
    <Link
      css={{
        ...(white && { "&:not(:hover)": { color: "white !important" } }),
      }}
      {...props}
    />
  </Title>
);

export default ({ homePath = "/" }) => (
  <Space size="large">
    <Space size="middle" css={mq({ display: ["none", "flex"] })}>
      <MenuLink white to={homePath} state={{ scrollTo: "mission" }}>
        {useTranslation("mission")}
      </MenuLink>
      <MenuLink white to={homePath} state={{ scrollTo: "product" }}>
        {useTranslation("product")}
      </MenuLink>
      <MenuLink white to={homePath} state={{ scrollTo: "pricing" }}>
        {useTranslation("pricing")}
      </MenuLink>
      <MenuLink white to={homePath} state={{ scrollTo: "about" }}>
        {useTranslation("about")}
      </MenuLink>
      <MenuLink white to="/blog">
        Blog
      </MenuLink>
    </Space>
    <Dropdown
      placement="bottomRight"
      css={[
        { fontSize: "1.2em", cursor: "pointer" },
        mq({ display: ["block", "none"] }),
      ]}
      overlay={
        <Menu>
          <Menu.Item icon={<BulbOutlined />}>
            <MenuLink to={homePath} state={{ scrollTo: "mission" }}>
              {useTranslation("mission")}
            </MenuLink>
          </Menu.Item>
          <Menu.Item icon={<GiftOutlined />}>
            <MenuLink to={homePath} state={{ scrollTo: "product" }}>
              {useTranslation("product")}
            </MenuLink>
          </Menu.Item>
          <Menu.Item icon={<DollarOutlined />}>
            <MenuLink to={homePath} state={{ scrollTo: "pricing" }}>
              {useTranslation("pricing")}
            </MenuLink>
          </Menu.Item>
          <Menu.Item icon={<QuestionOutlined />}>
            <MenuLink to={homePath} state={{ scrollTo: "about" }}>
              {useTranslation("about")}
            </MenuLink>
          </Menu.Item>
          <Menu.Item icon={<NotificationOutlined />}>
            <MenuLink to="/blog">Blog</MenuLink>
          </Menu.Item>
        </Menu>
      }
    >
      <MenuOutlined css={{ color: "white" }} />
    </Dropdown>
    <Link to={isAuthed() ? "/app" : "/login"}>
      <Button type="ghost" css={{ color: "white" }}>
        {useTranslation(isAuthed() ? "account" : "logIn")}
      </Button>
    </Link>
  </Space>
);
