import React from "react";
import mq from "./mq";
import { pY } from "./spacingShorthands";
import Container from "./Container";
import { Title, Paragraph } from "./typography";

export const Section = ({
  title,
  subtitle,
  children,
  size,
  white,
  textAlign = "center",
  ...props
}) => (
  <div {...props}>
    <Container size={size} css={mq(pY([48, 96]))}>
      <div css={{ textAlign }}>
        <Title white={white} level={2}>
          {title}
        </Title>
        <Paragraph css={{ fontSize: "1.2em" }}>{subtitle}</Paragraph>
      </div>
      {children}
    </Container>
  </div>
);

export const GridSection = ({ children, ...props }) => (
  <Section {...props}>
    <div
      css={[
        { marginTop: 48, display: "grid", gap: 16 },
        mq({ grid: ["repeat(3, auto) / 1fr", "1fr / repeat(3, 1fr)"] }),
      ]}
    >
      {children}
    </div>
  </Section>
);
