import React, { useState } from "react";
import { Button, Form, Input, Alert } from "antd";
import { MailOutlined, RocketOutlined } from "@ant-design/icons";
import tinycolor from "tinycolor2";
import { Link as GatsbyLink } from "gatsby";
import { trackNProgress } from "./nProgress";
import useTranslation from "../lang/useTranslation";
import { Title, Paragraph, Text } from "./typography";
import { mY } from "./spacingShorthands";
import useSubmitState from "./useSubmitState";
import { orange } from "../theme/colors";
import { Section } from "./sections";
import { callApi } from "./api";
import InlineForm from "./InlineForm";

export default ({ formName, textAlign = "center" }) => {
  const [isActionDone, setActionDone] = useState(false);

  const [form] = Form.useForm();
  const [isSubmitting, onSubmit] = useSubmitState(async ({ email }) => {
    await trackNProgress(
      callApi({ path: "collectEmail", method: "POST", params: { email } })
    );
    setActionDone(true);
  });
  const ctaQuestions = useTranslation("ctaQuestions");
  const t = useTranslation();

  return (
    <Section
      textAlign={textAlign}
      size={textAlign === "center" ? "md" : undefined}
      title={t("getFootprint")}
      css={{ backgroundColor: tinycolor(orange).lighten(30).toHexString() }}
    >
      {isActionDone ? (
        <Alert
          css={{ marginBottom: 16 }}
          message={t("thankYou")}
          description={t("getBackToYou")}
          type="success"
          showIcon
        />
      ) : (
        <InlineForm
          form={form}
          onFinish={onSubmit}
          name={formName}
          size="large"
          css={mY(24)}
        >
          <Form.Item
            css={{ flex: "1 !important" }}
            name="email"
            rules={[
              { required: true, message: t("required") },
              { type: "email", message: t("invalid") },
            ]}
          >
            <Input placeholder={t("workEmail")} disabled={isSubmitting} />
          </Form.Item>
          <Button
            loading={isSubmitting}
            type="primary"
            htmlType="sumbit"
            icon={<MailOutlined />}
          >
            {t("letsTalk")}
          </Button>
        </InlineForm>
      )}
      <Paragraph>
        <Text strong>{ctaQuestions.howLong.q}</Text> {ctaQuestions.howLong.a}
      </Paragraph>
      <Paragraph>
        <Text strong>{ctaQuestions.howMuch.q}</Text> {ctaQuestions.howMuch.a}
      </Paragraph>
      <Title level={4}>{t("saveSaveSave")}</Title>
      <div css={{ marginTop: 16, textAlign }}>
        <GatsbyLink to="/login">
          <Button type="primary" size="large" icon={<RocketOutlined />}>
            {t("getStarted")}
          </Button>
        </GatsbyLink>
      </div>
    </Section>
  );
};
